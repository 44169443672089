import React from "react";
import Receipt from "../components/receipt/receipt";

const isBrowser = typeof window !== "undefined";

const Rechnung = ({ location }) => {
  if (isBrowser) {
    return <Receipt customerData={location.state.data} />;
  }
  return null;
};

export default Rechnung;
