import React from "react";
import dateFormat from "dateformat";
import LOGO from "../../logos/tmc-black-orange.svg";

import {
  AddressBar,
  Container,
  DataBox,
  DataDisplayBox,
  DataDisplayBoxRechnung,
  DataFoot,
  DataHead,
  DividerText,
  FooterContainer,
  Header,
  P,
  P1,
  PersonInfoBar,
  PrintButton,
  RowNumber,
  RowText,
  SumContainer,
  SumHolder,
  TextContainer,
 
} from "./styles";

const Receipt = ({ customerData }) => {
  const { name, address, contractNum, device, model, serial } = customerData;
  const {
    date,
    receiptNumber,
    repairItems,
    repairPrice,
    repairItems1,
    repairPrice1,
    repairItems2,
    repairPrice2,
    repairItems3,
    repairPrice3,
    newDefect,
    post
  } = customerData.receipt;
  const newDate = dateFormat(date, "dd/mm/yyyy");
  const newPayDate = new Date();
  newPayDate.setDate(newPayDate.getDate() + 7);
  const payDateCustomer = dateFormat(newPayDate, "dd/mm/yyyy");

  const handlePrint = () => {
    document.title = `${contractNum}`;
    window.print();
    return false;
  };

  return (
    <Container>
      <PrintButton onClick={handlePrint}>Speichern</PrintButton>
      <div>
        <Header>
          <img src={LOGO} alt="tmc logo" width="240px" />
          <Address />
        </Header>
        <PersonInfo name={name} street={address} />
        <Divider text="Rechnung" size="1.5rem" right />
        <DataDisplay
          auftragsNummer={contractNum}
          rechnungsNummer={receiptNumber}
          rechnungsDatum={newDate}
          rechnungsDatumPay={payDateCustomer}
        />

        <DataDisplayDevice deviceType={device} model={model} serial={serial} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Divider text="Fehlerbild" size=".72rem" />
          <p
            style={{
              margin: " -.6rem 0 .9rem 0",
              paddingLeft: "3rem",
              fontFamily: "Open Sans",
              fontSize: ".8rem",
              marginRight: "auto",
            }}
          >
            {newDefect}
          </p>
        </div>

        <RechnungDisplay
          repair={repairItems}
          repairPrice={repairPrice}
          repair1={repairItems1}
          repairPrice1={repairPrice1}
          repair2={repairItems2}
          repairPrice2={repairPrice2}
          repair3={repairItems3}
          repairPrice3={repairPrice3}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <TotalSum
            repair={repairPrice}
            repair1={repairPrice1}
            repair2={repairPrice2}
            repair3={repairPrice3}
            post={post}
          />
        </div>
      </div>
      <Footer />
    </Container>
  );
};

const TextDisplay = () => {
  return (
    <TextContainer>
      <sup>Das Leistungsdatum entspricht dem Rechnungsdatum.</sup>

      <h4 style={{ margin: "0 0 .5rem 0" }}>Vielen Dank für Ihren Auftrag</h4>
      <sup style={{ fontSize: "xx-small" }}>
        Es gelten unsere allgemeinen Geschäftsbedingungen.
      </sup>
    </TextContainer>
  );
};

const Footer = () => {
  return (
    <div style={{ marginTop: "auto" }}>
      <TextDisplay />
      <div
        style={{
          width: "100%",
          borderTop: "1.8px solid black",
        }}
      >
        <FooterContainer>
          <div>
            <div style={{ fontWeight: 600 }}> Anschrift</div>
            <div>Solda GmbH</div>
            <div>Innsbrucker Platz 4</div>
            <div>10827 Berlin</div>
          </div>
          <div>
            <div style={{ fontWeight: 600 }}>Kontakt</div>
            <div>030 856 123 49</div>
            <div>www.notebookberlin.de</div>
            <div>info@notebookberlin.de</div>
          </div>
          <div>
            <div style={{ fontWeight: 600 }}>Bankverbindung</div>
            <div>IBAN: DE77 1001 0010 0276 1721 34</div>
            <div>BIC: PBNKDEFF</div>
            <div>Postbank Berlin</div>
          </div>
          <div>
            <div style={{ fontWeight: 600 }}>Steuer</div>
            <div>Ust-IdNr: DE/329425040</div>
            <div>HRB: 216317 B</div>
          </div>
        </FooterContainer>
        <sub
          style={{
            display: "block",
            fontFamily: "Open Sans",
            textAlign: "center",
            fontSize: "0.66rem",
            marginTop: "1.5rem",
          }}
        >
          TMC-TEC ist eine Marke der Solda GmbH.
        </sub>
      </div>
    </div>
  );
};

const DataDisplay = ({
  auftragsNummer,
  rechnungsNummer,
  rechnungsDatum,
  rechnungsDatumPay,
}) => {
  return (
    <DataDisplayBox>
      <Data name="Auftragsnummer" number={auftragsNummer} />
      <Data name="Rechnungsnummer" number={rechnungsNummer} />
      <Data name="Rechnungsdatum" number={rechnungsDatum} />
      <Data name="Fälligkeit bis zum" number={rechnungsDatumPay} />
    </DataDisplayBox>
  );
};
const DataDisplayDevice = ({ serial, deviceType, model }) => {
  return (
    <DataDisplayBox>
      <Data name="Gerätetyp" number={deviceType} />
      <Data name="Gerätebezeichnung" number={model} />
      <Data name="Seriennummer" number={serial} />
    </DataDisplayBox>
  );
};

const Data = ({ name, number }) => (
  <DataBox>
    <DataHead>{name}</DataHead>
    <DataFoot>{number}</DataFoot>
  </DataBox>
);

const TotalSum = ({ repair, repair1, repair2, repair3, post }) => {
  const totalPrice = parseFloat(
    (repair ?? 0.0) + (repair1 ?? 0.0) + (repair2 ?? 0.0) + (repair3 ?? 0.0) + (post ?? 0.0)
  ).toFixed(2);
  const totalPriceChange = totalPrice.replace(/\./g, ",");
  const tax = parseFloat((19 * totalPrice) / 100).toFixed(2);
  const taxChange = tax.replace(/\./g, ",");
  const priceWithoutTax = parseFloat(totalPrice - tax)
    .toFixed(2)
    .replace(/\./g, ",");
  const postPrice = parseFloat(post).toFixed(2).replace(/\./g, ",");;
  return (
    <SumContainer>
       <SumHolder>
        <RowText>Versand</RowText>
        <RowNumber>{postPrice} €</RowNumber>
      </SumHolder>
      <SumHolder>
        <RowText>Summe Netto</RowText>
        <RowNumber>{priceWithoutTax} €</RowNumber>
      </SumHolder>
      <SumHolder>
        <RowText>Davon 19% Ust.</RowText>
        <RowNumber>{taxChange} €</RowNumber>
      </SumHolder>
      <SumHolder>
        <RowText bg="#eee" bold>
          Zu zahlender Betrag
        </RowText>
        <RowNumber bg="#eee" bold>
          {totalPriceChange} €
        </RowNumber>
      </SumHolder>
    </SumContainer>
  );
};

const DataRechnung = ({ repair, repair1, repair2, repair3 }) => {
  return (
    <DataBox style={{ width: "70%", textAlign: "left" }}>
      <DataHead style={{ fontWeight: "600", paddingLeft: "1rem" }}>
        Reparatur
      </DataHead>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repair}</DataFoot>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repair1}</DataFoot>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repair2}</DataFoot>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repair3}</DataFoot>
    </DataBox>
  );
};

const DataRechnungPrice = ({
  repairPrice,
  repairPrice1,
  repairPrice2,
  repairPrice3,
}) => {
  const repairPriceFirst =
    parseFloat(repairPrice).toFixed(2).replace(/\./g, ",") + " €" ?? 0;
  const repairPriceSecond = repairPrice1
    ? parseFloat(repairPrice1).toFixed(2).replace(/\./g, ",") + " €"
    : null;
  const repairPriceThird = repairPrice2
    ? parseFloat(repairPrice2).toFixed(2).replace(/\./g, ",") + " €"
    : null;
  const repairPriceFourth = repairPrice3
    ? parseFloat(repairPrice3).toFixed(2).replace(/\./g, ",") + " €"
    : null;
  return (
    <DataBox style={{ width: "30%", textAlign: "left" }}>
      <DataHead style={{ fontWeight: "600", paddingLeft: "1rem" }}>
        Einzelpreis
      </DataHead>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repairPriceFirst}</DataFoot>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repairPriceSecond}</DataFoot>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repairPriceThird} </DataFoot>
      <DataFoot style={{ paddingLeft: "1rem" }}>{repairPriceFourth} </DataFoot>
    </DataBox>
  );
};

const RechnungDisplay = ({ ...props }) => {
  return (
    <DataDisplayBoxRechnung>
      <DataRechnung {...props} />
      <DataRechnungPrice {...props} />
    </DataDisplayBoxRechnung>
  );
};

const PersonInfo = ({ name, street, plz }) => {
  return (
    <PersonInfoBar>
      <P1>Kunde</P1>
      <P1>{name} </P1>
      <P1>{street}</P1>
      <P1>{plz} </P1>
    </PersonInfoBar>
  );
};

const Divider = ({ text, size, width, right }) => (
  <DividerText size={size} width={width} right={right}>
    {text}
  </DividerText>
);

const Address = () => {
  return (
    <AddressBar>
      <P>Innsbrucker Platz 4, 10827 Berlin </P>
      <P>030 856 123 49</P>
      <P>www.notebookberlin.de</P>
      <P>info@notebookberlin.de</P>
    </AddressBar>
  );
};

export default Receipt;
